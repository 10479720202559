import React from "react"
import * as faceapi from 'face-api.js'
import {onPlay, getCurrentFaceDetectionNet, isFaceDetectionModelLoaded} from '../utils/webcam';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FacebookProvider from 'react-facebook/module/FacebookProvider';
import Like from 'react-facebook/module/Like';
import Box from '@material-ui/core/Box';
import SEO from '../components/seo';
import {TwitterShareButton} from 'react-twitter-embed';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.video = React.createRef();
    this.canvas = React.createRef();
    this.state = {fps: ``, time: ``, ageSnapshots: [], loading: true, canvasHeight: 0, canvasWidth: 0};
  }

  async componentDidMount() {
    if (!isFaceDetectionModelLoaded()) {
      await getCurrentFaceDetectionNet().load('/weights')
    }
    this.setState({loading: false});
    await faceapi.nets.ageGenderNet.load('/weights')
    await onPlay(this);
    this.video.current.srcObject = await navigator.mediaDevices.getUserMedia({ video: true })
  }

  getAvgAge() {
    if (this.state.ageSnapshots.length === 0) {
      return 0;
    }
    return this.state.ageSnapshots.reduce((total, a) => total + a) / this.state.ageSnapshots.length;
  }

  renderVideoBox() {
    let offsetTop = 0;
    let offsetLeft = 0;

    if (this.video && this.video.current) {
      offsetTop = this.video.current.offsetTop;
      offsetLeft = this.video.current.offsetLeft;
    }

    return (
      <div>
        <div>
          <video ref={this.video}
                 width={'100%'}
                 onLoadedMetadata={() => {
                   this.setState({canvasWidth: this.video.width, canvasHeight: this.video.height})
                 }}
                 id="inputVideo" autoPlay playsInline muted />
          <canvas style={{position: 'absolute', top: offsetTop, left: offsetLeft}}
                  ref={this.canvas} id="overlay"/>
        </div>
        <Grid container justify={'space-between'}>
          <Grid item>
            <label>Age (Estimate): {Math.trunc(this.getAvgAge())}</label>
          </Grid>
          <Grid item>
            <label>Time: {this.state.time}</label>
          </Grid>
          <Grid item>
            <label>Frames per second: {this.state.fps}</label>
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    return <Container style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <Container style={{maxWidth: '90vw'}}>
        <SEO title={`predict-age.com`}
             subtitle={`Predict your age using a webcam`}
        />
        <h1>Predict your Age</h1>
        <div>
          Face the camera and the webcam will try to detect your face. If you see a visible box, that means its working!
        </div>
        <div style={{marginTop: '15px'}}>
          {this.state.loading ? <CircularProgress/> : this.renderVideoBox()}
        </div>
        <div style={{fontSize: '10px', marginTop: '15px'}}>
          This web app uses HTML5 camera to capture snapshots and uses <a href="https://github.com/justadudewhohacks/face-api.js/">face-api</a> to call facial recognition machine learning APIs.
        </div>
        <div style={{fontSize: '10px', marginTop: '15px'}}>
          <b>Privacy Policy</b>: We do not collect any data processed by webcam devices. Facial recognition is done all from the browser environment.
          All CNN (convolutional neural network) models and weights are pre-trained and loaded into Tensorflow JS. Tensorflow will use WebGL to optimize performance if the device supports it.
          Age estimate is just the average of most recent successful age predictions.
        </div>
        <div style={{fontSize: '10px', marginTop: '15px'}}>
          <b>Resources</b>: <a href={"https://www.researchgate.net/publication/340426963_Age_estimation_from_faces_using_deep_learning_A_comparative_analysis"}>Age estimation from faces using deep learning</a>, <a href={"https://pjreddie.com/darknet/yolov2/"}>YOLOv2 (face detection)</a>, <a href={"https://arxiv.org/abs/1610.02357"}>Xception (facial attributes)</a>
        </div>
        <div style={{marginTop: '15px'}}>
          <FacebookProvider appId={'371174540606679'}>
            <Like href="https://predict-age.com" colorScheme="dark" showFaces share />
          </FacebookProvider>
          <TwitterShareButton
            url={'https://predict-age.com'}
            options={{ text: 'Predict your age with a webcam and machine learning!', via: 'AgePredict' }}
          />
        </div>
      </Container>
    </Container>
  }
}
export default Home;
