import * as faceapi from 'face-api.js'

const SSD_MOBILENETV1 = 'ssd_mobilenetv1'
const TINY_FACE_DETECTOR = 'tiny_face_detector'
const selectedFaceDetector = TINY_FACE_DETECTOR;

// ssd_mobilenetv1 options
let minConfidence = 0.5
let inputSize = 512
let scoreThreshold = 0.5

let forwardTimes = []
let predictedAges = []
let withBoxes = true

function updateTimeStats(timeInMs) {
  forwardTimes = [timeInMs].concat(forwardTimes).slice(0, 30)
  const avgTimeInMs = forwardTimes.reduce((total, t) => total + t) / forwardTimes.length
  return {
    time: `${Math.round(avgTimeInMs)} ms`,
    fps: `${faceapi.utils.round(1000 / avgTimeInMs)}`,
  }
}

export function getCurrentFaceDetectionNet() {
  if (selectedFaceDetector === SSD_MOBILENETV1) {
    return faceapi.nets.ssdMobilenetv1
  }
  if (selectedFaceDetector === TINY_FACE_DETECTOR) {
    return faceapi.nets.tinyFaceDetector
  }
}

function getFaceDetectorOptions() {
  return selectedFaceDetector === SSD_MOBILENETV1
    ? new faceapi.SsdMobilenetv1Options({ minConfidence })
    : new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold })
}

export function isFaceDetectionModelLoaded() {
  return !!getCurrentFaceDetectionNet().params
}

function interpolateAgePredictions(age) {
  predictedAges = [age].concat(predictedAges).slice(0, 30);
  const avgPredictedAge = predictedAges.reduce((total, a) => total + a) / predictedAges.length;
  return avgPredictedAge;
}

export async function onPlay(component) {
  const {video, canvas} = component;
  const videoDOM = video.current;
  const canvasDOM = canvas.current;

  if (videoDOM.paused || videoDOM.ended || !isFaceDetectionModelLoaded()) {
    return setTimeout(() => onPlay(component));
  }

  const options = getFaceDetectorOptions();
  const ts = Date.now();

  const result = await faceapi.detectSingleFace(videoDOM, options)
    .withAgeAndGender();

  component.setState(updateTimeStats(Date.now() - ts));

  canvasDOM.style.display ='none';
  if (result) {
    canvasDOM.style.display ='block';
    const dims = faceapi.matchDimensions(canvasDOM, videoDOM, true)

    const resizedResult = faceapi.resizeResults(result, {height: videoDOM.clientHeight, width: videoDOM.clientWidth});
    if (withBoxes) {
      faceapi.draw.drawDetections(canvasDOM, resizedResult)
    }
    const { age, gender, genderProbability } = resizedResult

    // interpolate gender predictions over last 30 frames
    // to make the displayed age more stable
    const interpolatedAge = interpolateAgePredictions(age);
    const recordedAges = [...component.state.ageSnapshots];
    recordedAges.push(faceapi.utils.round(interpolatedAge, 0));
    if (recordedAges.length > 300) {
      recordedAges.shift();
    }
    component.setState({ageSnapshots: recordedAges});

    new faceapi.draw.DrawTextField(
      [
        `${faceapi.utils.round(interpolatedAge, 0)} years`,
        `${gender} (${faceapi.utils.round(genderProbability)})`
      ],
      resizedResult.detection.box.bottomLeft,
    ).draw(canvasDOM)
  }

  setTimeout(() => onPlay(component))
}


